<template>
  <div class="lecturer">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="lecturer-cont">
      <TeacherAuth :userDetail="userDetail" :isInfo="true" @editSuccess="editSuccess"></TeacherAuth>
    </div>
  </div>
</template>

<script>
import TeacherAuth from './components/teacherAuth'
import util from '@/utils/tools.js';
export default {
  name: "LecturerView",
  data() {
    return {
      userDetail: {}
    }
  },
  components: {
    TeacherAuth
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let params = {
        with_weixin_subscribe: 1
      };
      this.api.user.userDetail(params).then((res) => {
        console.warn("详情信息",res);
        this.userDetail = res.user;
        this.userDetail.created_at = util.timeFormatter(new Date(+res.user.created_at * 1000), 'yyyy-MM-dd hh:mm');
      });
    },
    editSuccess() {
      this.getDetail();
    },
  }
}
</script>

<style scoped lang="scss">
.lecturer {
  margin: 20px;
  .lecturer-cont {
    padding: 20px;
    background: #FFFFFF;
  }
}
</style>
